.social-border-left{
    border-left: 1px solid;
    border-color: #505050
}

.social-border-right{
    border-right: 1px solid;
    border-color: #505050
}

.text-left{
    text-align: start;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }